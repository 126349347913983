<template>
  <div class="index">
    <div class="main_layout">
      <a-alert
        message="登录成功，请选择需要的操作。"
        class="ant-alert-banner"
        type="success"
        show-icon
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.ant-alert-banner {
  margin-bottom: 34px;
  border: 0;
  border-radius: 0;
}
</style>
